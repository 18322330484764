export const insertPosition = (value: string, element: HTMLElement) => {
  const startPos = element.selectionStart
  const endPos = element.selectionEnd
  let cursorPos = startPos
  const tmpStr = element.value

  if (value === null) {
    return
  }

  const result =
    tmpStr.substring(0, startPos) +
    value +
    tmpStr.substring(endPos, tmpStr.length)

  setTimeout(() => {
    element.focus()
    cursorPos += value.length
    element.selectionStart = element.selectionEnd = cursorPos
  }, 10)

  return result
}
