export const translateTransferMode = (mode: string) => {
  const mapper: { [_: string]: string } = {
    transfer_department: 'Transferir: Departamento',
    transfer_attendant: 'Transferir: Atendente',
    fishing: 'Pesca',
    sequential: 'Sequencial',
  }

  return mapper[mode]
}
