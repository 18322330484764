import { useTimeoutPoll } from '@vueuse/core'

export const markUserOnline = () => {
  const { isActive, pause, resume } = useTimeoutPoll(async () => {
    await window.axios.post(route('online.user'))
  }, 30_000, { immediate: true })

  return {
    isActive,
    pause,
    resume
  }
}
