export const shortNum = (number: number, precision = 1) => {
  let numberFormat: string | number = 0
  let suffix = ''

  if (number < 900) {
    numberFormat = number.toFixed(precision)
  } else if (number < 900000) {
    // 0.9k-850k
    numberFormat = (number / 1000).toFixed(precision)
    suffix = 'K'
  } else if (number < 900000000) {
    // 0.9m-850m
    numberFormat = (number / 1000000).toFixed(precision)
    suffix = 'M'
  } else if (number < 900000000000) {
    // 0.9b-850b
    numberFormat = (number / 1000000000).toFixed(precision)
    suffix = 'B'
  } else {
    // 0.9t+
    numberFormat = (number / 1000000000000).toFixed(precision)
    suffix = 'T'
  }

  if (precision > 0) {
    const dotzero = '.' + '0'.repeat(precision)
    numberFormat = numberFormat.replace(dotzero, '')
  }

  return `${numberFormat} ${suffix}`
}
